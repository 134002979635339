<template>
  <div class="drivers-step1-content">
    <!-- 按钮栏 -->
    <div class="bg-white flex flex-row">
      <div class="pl-24 pr-6 flex-1">
        <div class="d-flex justify-space-between">
          <label class="require ml-10 text-14 text-main font-500"
            >行驶证主副页(<span class="text-danger">挂车</span>)</label
          >
          <div class="font-6" v-if="truckRef.reasonKeys.includes('drivingLicenseMainUrl')">
            不正确
          </div>
        </div>
        <div class="truck-card-wrap py-4">
          <tos-uploader
            class="truck-card"
            v-model="truckLicenseMainFileList"
            @afterUploaded="afterUploadedTruckCard"
            :max-count="1"
            bgType="xingshizheng-zhu"
          />
        </div>
      </div>
      <div class="pl-6 pr-24 flex-1">
        <div class="d-flex justify-space-between">
          <label class="ml-5 text-14 text-main font-500">行驶证其他页</label>
        </div>
        <div class="truck-card-wrap  py-4">
          <tos-uploader
            class="truck-card"
            v-model="truckLicenseSideFileList"
            :max-count="1"
            bgType="xingshizheng-zhu"
          />
        </div>
      </div>
    </div>
    <div class="relative pt-8 text-center text-note text-12">上传行驶证照片，可快速识别车牌号</div>
    <div class="form noborder px-24">
      <div class="form-title flex-nowrap d-flex mb-8">
        <div class="important" :class="{ titleError: truckRef.reasonKeys.includes('carLicensePlate') }">挂车牌照号</div>
        <div class="font-12" v-if="truckRef.reasonKeys.includes('carLicensePlate')">
          不正确
        </div>
      </div>
      <div class="d-flex justify-space-between" @click="isAmendTruckAuthInfo ? null : (popVisible = true)">
        <span
          class="carnumber-box"
          :class="isAmendTruckAuthInfo ? 'text-muted' : ''"
          v-for="(item, index) in carNumberPro.length === 0 ? ['', '', '', '', '', '', ''] : carNumberPro"
          :key="index"
        >
          {{ item }}
        </span>
      </div>
    </div>
    <van-form class="px-12" ref="form">
      <template v-for="(item, key) in FormObj">
        <van-field
          :readonly="['select', 'datePicker'].includes(item.type)"
          v-if="!item.hidden"
          @click="['select', 'datePicker'].includes(item.type) ? (FormObj[key].visible = true) : ''"
          :required="item.rules[0].required"
          :value="truckRef[key]"
          @input="handleInput($event, key)"
          input-align="right"
          :name="item.name"
          :placeholder="'请输入'"
          :rules="item.rules"
          :type="item.type"
          :autosize="item.autosize"
          rows="1"
          :key="key"
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes(key) }">
              <div>
                {{ getLabel(key) }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
          <template v-if="item.extra" #extra>
            <div class="ml-8">{{ item.extra }}</div>
          </template>
        </van-field>
      </template>
    </van-form>

    <div>
      <div class="truck-btn-box">
        <Button size="large" :disabled="next" @click="handleToNext" type="primary">下一步</Button>
      </div>
    </div>
    <Popup v-model="popVisible" position="bottom" round>
      <KeyBoard
        :defaultValue="carNumberPro"
        defaultType="trailer"
        @getPlateLicense="getPlateLicense"
        @hide="popVisible = false"
      />
    </Popup>

    <Popup v-model="FormObj.plateColor.visible" round position="bottom">
      <CheckPorps
        title="车牌颜色选择"
        v-model="truckRef.plateColor"
        :columns="FormObj.plateColor.options"
        @confirm="e => handlePopConfirm(e, 'plateColor')"
        @cancel="FormObj.plateColor.visible = false"
      />
    </Popup>
    <Popup v-model="FormObj.truckTypeName.visible" :style="{ height: '100%' }" position="left">
      <TruckTypePicker
        title="请选择车辆类型"
        v-model="truckRef.truckTypeName"
        :columns="FormObj.truckTypeName.options"
        @confirm="e => onTruckTypeChange(e, 'truckType')"
        @cancel="FormObj.truckTypeName.visible = false"
      />
    </Popup>

    <!-- 挂车牌照 -->
    <!-- <Popup v-model="FormObj.trailerPlateNumber.visible" position="bottom" round>
      <KeyBoard
        :defaultValue="truckRef.trailerPlateNumber"
        @getPlateLicense="getTrailerNo"
        @hide="FormObj.trailerPlateNumber.visible = false"
      />
    </Popup> -->
    <ConfirmModal :carInfo="truckRef" ref="confirm" @handleComfirm="onConfirm" />
  </div>
</template>
<script>
import TosUploader from '@/components/uploader/uploader.vue';
import { defineComponent, ref, computed, reactive, onMounted, nextTick, watch } from '@vue/composition-api';
import KeyBoard from '../../components/carBoard';
import { Button, Popup, Toast, Dialog } from 'vant';
import { useRouter } from '@/utils/compsitionHack';
import { useTrailerVerifiedStore } from '@/store/trailer';
import { apiOcrVehicleLicense } from '@/api/ocr';
import { PageEnum } from '@/enums/pageEnum';
import { useUserStore } from '@/store/user';
import { apiGetLicensesByPlate } from '@/api/common';
import { getTrailerStep1FormData } from '../../constants';

import CheckPorps from '../../components/checkCarProps';
import TruckTypePicker from '../../components/TruckTypePicker';
import { apiGetTrailerType } from '@api/mine';
import ConfirmModal from '../../components/submit-popup.vue';
import { getOrgnazitionByPlate } from '../../components/utils';
import { formatWeightOcr } from '@/utils/ocr';

export default defineComponent({
  name: 'carAddStep1',
  components: {
    TosUploader,
    Button,
    Popup,
    KeyBoard,
    Toast,
    CheckPorps,
    TruckTypePicker,
    ConfirmModal
  },
  setup(_, ctx) {
    const userVerifiedStore = useTrailerVerifiedStore();

    const popVisible = ref(false);
    const disabled = ref(false); // 全局禁用
    const router = useRouter();
    const next = ref(false);
    const userStore = useUserStore();

    const carNumberPro = computed(() => userVerifiedStore.verifiedInfo.carLicensePlate);
    const truckRef = computed(() => userVerifiedStore.verifiedInfo);
    const truckLicenseMainFileList = ref([]);
    const truckLicenseSideFileList = ref([]);

    const isAmendTruckAuthInfo = computed(() => !!userVerifiedStore?.verifiedInfo?.trailerId);

    const FormData = getTrailerStep1FormData(truckRef, userStore.showReviewKeys);
    const FormObj = reactive(FormData);

    onMounted(() => {
      // userVerifiedStore.updateAuditStatusInfo(userVerifiedStore?.verifiedInfo.auditStatus);
      if (userVerifiedStore?.verifiedInfo?.drivingLicenseMainUrl) {
        truckLicenseMainFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.drivingLicenseMainUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.drivingLicenseSideUrl) {
        truckLicenseSideFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.drivingLicenseSideUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }

      getCarTypes();
    });

    const getCarTypes = async () => {
      // 拉取车辆类型
      const res = await apiGetTrailerType();
      FormObj.truckTypeName.options = res.data;
      res.data
        .map(item => item.secondCarTypes)
        .flat(2)
        .forEach(item => {
          if (item.name === truckRef.value.truckTypeName) {
            userVerifiedStore.updateInfo({
              truckTypeName: item.name,
              truckTypeCode: item.code
            });
          }
        });
      if (
        // 没有识别出来车辆类型
        !res.data
          .map(item => item.secondCarTypes)
          .flat(2)
          .map(i => i.name)
          .includes(truckRef.value.truckTypeName)
      ) {
        userVerifiedStore.updateInfo({
          truckTypeName: '',
          truckTypeCode: ''
        });
      }
    };
    const getCarTypeCode = name => {
      const data = FormObj.truckTypeName.options
        .map(item => item.secondCarTypes)
        .flat(2)
        .find(it => it.name === name);
      if (data) {
        return {
          truckTypeCode: data.code,
          truckTypeName: data.name
        };
      }
      return null;
    };

    const getPlateLicense = async number => {
      userVerifiedStore.updateInfo({
        carLicensePlate: number
      });
      afterPlateChange(number);
    };

    const afterPlateChange = async carLicensePlate => {
      const carValidErr = vaildateCarNumber(carNumberPro.value);
      if (carValidErr) {
        Toast.fail(carValidErr);
        return;
      }
      const isExist = await userVerifiedStore.checkTruckExist(carLicensePlate);
      if (isExist === 1) {
        const msg = `【${carLicensePlate}】 已存在，是否添加车辆`;
        Dialog.confirm({
          message: msg
        }).then(
          async () => {
            const isSuccess = await userVerifiedStore.addTruckByExist(carLicensePlate);
            Toast.clear();
            if (isSuccess) {
              Toast.success('添加成功');
              userVerifiedStore.updateInfo({
                // 跳转之前做缓存数据的清空
                drivingLicenseMainUrl: '',
                carLicensePlate: ''
              });
              nextTick(() => {
                router.push(PageEnum.BASE_CAR_LIST, { query: { type: 'trailer' } });
              });
            } else {
              userVerifiedStore.updateInfo({
                carLicensePlate: ''
              });
            }
          },
          () => {
            userVerifiedStore.updateInfo({
              carLicensePlate: ''
            });
          }
        );
      } else if (isExist === -1) {
        userVerifiedStore.updateInfo({
          carLicensePlate: ''
        });
      }
    };

    const validateForm = async (...params) => {
      const form = ctx.refs.form;
      let flag = true;
      if (form) {
        try {
          flag = await form.validate(...params);
          return true;
        } catch (e) {
          console.log(e);
          return false;
        }
      }
      return flag;
    };

    const afterUploadedTruckCard = async file => {
      // userVerifiedStore.updateImagePass(false);
      file.status = 'uploading';
      file.message = '识别中';
      disabled.value = true;
      next.value = true;
      try {
        const res = await apiOcrVehicleLicense({ imageUrl: file.url });
        const { data } = res;
        const {
          carNumber,
          vehicleHeight,
          vehicleLength,
          vehicleWidth,
          vin,
          owner,
          issueDate,
          vehicleType,
          useCharacter,
          sealOrganize,
          fileNo,
          registerDate
        } = data;
        const { totalWeight, loadWeight } = formatWeightOcr(data);
        let changedData = {
          owner,
          registerDate: registerDate && strDateTime(registerDate) ? registerDate : '',
          issueDate: issueDate && strDateTime(issueDate) ? issueDate : '',
          outlineDimensionHeight: vehicleHeight,
          outlineDimensionLength: vehicleLength,
          outlineDimensionWidth: vehicleWidth,
          identifyCode: vin,
          useNature: useCharacter,
          totalWeight,
          loadWeight,
          issueOrganization: sealOrganize,
          fileNumber: fileNo
        };
        const typeObj = getCarTypeCode(vehicleType);
        if (typeObj) {
          changedData = { ...changedData, ...typeObj };
        }
        let goodData = Object.keys(changedData).reduce((res, key) => {
          if (changedData[key]) {
            res[key] = changedData[key];
          }
          return res;
        }, {});
        if (!isAmendTruckAuthInfo.value && carNumber) {
          getPlateLicense(carNumber);
        }
        userVerifiedStore.updateInfo(goodData);
      } catch (error) {
        // userVerifiedStore.updateImagePass(true);
        disabled.value = false;
        file.status = 'done';
      } finally {
        next.value = false;
      }
    };

    const strDateTime = str => {
      let r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
      if (r == null) return false;
      let d = new Date(r[1], r[3] - 1, r[4]);
      return d.getFullYear() == r[1] && d.getMonth() + 1 == r[3] && d.getDate() == r[4];
    };

    const dealValueBeforeNext = async () => {
      try {
        const res = await apiGetLicensesByPlate({
          licensePlate: carNumberPro.value.slice(0, 2),
          pageNum: 1,
          pageSize: 10
        });
        const { records } = res.data;
        if (!records || !records[0]) return;
        const {
          issueOrganization,
          totalWeight,
          useNature,
          roadTransportNumber,
          roadTransportPermitNumber
        } = userVerifiedStore.verifiedInfo;

        const obj = {};
        if (!issueOrganization && records?.[0]?.province) {
          const val = getOrgnazitionByPlate(records[0]);
          val && (obj.issueOrganization = val);
        }
        if (!useNature) {
          obj.useNature = totalWeight <= 4500 ? '非营运' : '货运';
        }
        const districtCode = records[0]?.districtCode;
        if (totalWeight <= 4500) {
          if (districtCode) {
            obj.roadTransportNumber = `${districtCode}000000`;
            obj.roadTransportPermitNumber = `${districtCode}000000`;
            userVerifiedStore.setRoadDisabled(true);
          } else {
            userVerifiedStore.setRoadDisabled(false);
          }
        } else {
          userVerifiedStore.setRoadDisabled(false);
          if (!roadTransportNumber) {
            obj.roadTransportNumber = `${districtCode}000001`;
          }
          if (!roadTransportPermitNumber) {
            obj.roadTransportPermitNumber = `${districtCode}000002`;
          }
        }
        userVerifiedStore.updateInfo(obj);
      } catch (e) {
        //
        userVerifiedStore.setRoadDisabled(false);
      }
    };
    const onConfirm = async () => {
      if (ctx.root.$route.query.isEdit !== 'true') {
        await dealValueBeforeNext();
      }
      userVerifiedStore.updateInfo({
        step: 1, // 如果验证通过
        drivingLicenseMainUrl: truckLicenseMainFileList?.value[0]?.fileName || '',
        drivingLicenseSideUrl: truckLicenseSideFileList?.value[0]?.fileName || ''
      });
    };

    const vaildateCarNumber = carNumber => {
      const carLicencePattern = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼]{1}[A-Z]{1}[A-Z0-9]{4,5}[挂超]{1}$/;
      if (!carNumber) return '车牌号不能为空！';
      if (!carLicencePattern.test(carNumber)) return '车牌号格式错误';
      return '';
    };
    const handleToNext = async () => {
      //TODO: 调用一个验证接口 验证该车牌是否已经在平台中添加
      if (!truckLicenseMainFileList?.value[0]?.fileName) {
        Toast.fail('图片不能为空！');
        return;
      }

      // if (userVerifiedStore?.imagePass) {
      //   Toast('图片识别未通过，请重新上传');
      //   return;
      // }
      const carValidErr = vaildateCarNumber(carNumberPro.value);
      if (carValidErr) {
        Toast.fail(carValidErr);
        return;
      } else {
        const isValid = await validateForm();
        if (!isValid) return;
        const istre = ctx.refs.confirm?.show();
        if (!istre) return;
      }
    };

    const handleInput = (value, key) => {
      truckRef.value[key] = value;
    };

    const handlePopConfirm = (e, typeName) => {
      userVerifiedStore.updateInfo({
        [typeName]: e.value,
        [`${typeName}Code`]: e.key
      });
      FormObj[typeName].visible = false;
    };
    const onTruckTypeChange = e => {
      userVerifiedStore.updateInfo({
        truckTypeName: e.value,
        truckTypeCode: e.key
      });
      FormObj.truckTypeName.visible = false;
    };
    const getTrailerNo = e => {
      userVerifiedStore.updateInfo({
        trailerPlateNumber: e
      });
    };

    const getLabel = key => {
      if (key === 'loadWeight') {
        return '核定载质量';
      }
      return FormObj[key].label;
    };

    return {
      next,
      truckRef,
      popVisible,
      carNumberPro,
      getPlateLicense,
      handleToNext,
      truckLicenseMainFileList,
      truckLicenseSideFileList,
      afterUploadedTruckCard,

      isAmendTruckAuthInfo,
      FormObj,
      handleInput,
      handlePopConfirm,
      getTrailerNo,
      getLabel,

      onTruckTypeChange,
      onConfirm
    };
  }
});
</script>
<style lang="less">
.truck-card-wrap {
  // padding: 0.04rem 0.08rem;
  .truck-card {
    width: 100%;
    height: 1rem;
  }
}
.form-title {
  position: relative;
  display: inline-block;
  width: auto;
  .important::after {
    position: absolute;
    right: -10px;
    color: #ee0a24;
    font-size: 24px;
    top: -2px;
    text-align: center;
    vertical-align: middle;
    content: '*';
  }
}
</style>
